const initialState = {
    data: {},
    loading: false,
    success: false,
    error: false,
  };
  
  const getImageReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_LOADING":
        return { ...state, data: {}, loading: true, success: false, error: false };
      case "GET_SUCCESS":
        return { ...state, data: action.payload, loading: false, success: true, error: false };
      case "GET_ERROR":
        return { ...state, data: {}, loading: false, success: false, error: true };
      default:
        return state;
    }
  };
  
  export default getImageReducer;
  