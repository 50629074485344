import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadImage from "../Assets/Icon/UploadIcon.svg";
import Button from "../common/button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import { uploadImageSticker } from "../store/action/apiActions";
import PrintableTShirt from "./PrintableTShirt.jsx";
import Loader from "../common/Loader.jsx";


// export var baseUrl = 'http://localhost:8080';
export var baseUrl = 'https://glitch-backend-node-9la3v.ondigitalocean.app';

const UploadImageComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shirtImage, setShirtImage] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const { imageData, imageDataSuccess, imageDataLoading } = useSelector(
    (state) => {
      return {
        imageData: state.UploadImage.data,
        imageDataSuccess: state.UploadImage.success,
        imageDataLoading: state.UploadImage.loading,
      };
    }
  );
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];

    if (imageFile) {
      // Check if the image size is within the limit (5MB)
      if (imageFile.size > 5 * 1024 * 1024) {
        setIsValid(false);
        setErrorMessage(
          "Image size exceeds the 5MB limit. Please choose a smaller image."
        );
        return;
      }

      // Check if the file type is valid (PNG or JPG)
      if (!/\.png$|\.jpg$|\.jpeg$/i.test(imageFile.name)) {
        setIsValid(false);
        setErrorMessage("Invalid file type. Please choose a PNG or JPG image.");
        return;
      }

      setShirtImage(imageFile);
      setIsValid(true);
      setErrorMessage("");
    }
  };

  console.log(shirtImage, "imageFile");
  const handleSubmit = async () => {
    if (!shirtImage) {
      return; // Don't proceed if no valid image is selected
    }

    const formData = new FormData();
    formData.append("image", shirtImage);

    const url =
      `${baseUrl}/user/test/sticker` ; // Your API endoint

    // Dispatch the action to upload and process the image


    await dispatch(uploadImageSticker(url, formData)); // Pass formData as the payload
  };
  // let resID = `NJCk304Hk`;
  // useEffect(() => {
  //   if (resID) navigate(`/selected-product/${resID}`);
  // }, [resID, navigate]);

  useEffect(() => {
    if (imageDataSuccess && imageData) {
      navigate(`/selected-product/${imageData.data.sticker.reqId}`);
    }
  }, [imageDataSuccess, imageData]);

  // console.log(imageDataSuccess ,imageData, "imageData");

  // console.log(data.loading, "data");
  return (
    <section className="mt-6 md:mt-10 mb-24 lg:mb-0">
      <div className="container mx-auto px-4 md:px-8 lg:px-12">
        <div className="flex flex-col items-center justify-center">
          {imageDataLoading ? (
            <Loader />
          ) : (
            <>
              <label
                htmlFor="cover-photo"
                className="block text-lg mb-4 font-medium leading-6 text-gray-900"
              >
                Upload Image
              </label>
              <div className="mt-2 mb-7 md:px-14 w-full md:w-auto">
                <div className="text-center  px-6 flex justify-center mb-2 py-10 flex-col rounded-lg bg-[#F8F8FF] border border-dashed border-[#ff56d5] w-full md:w-[465px]">
                  <img
                    src={UploadImage}
                    alt="upload"
                    className="mx-auto mb-5"
                  />
                  <div className="mt-4 mb-3 flex text-md font-semibold text-center justify-center leading-6 text-[#0F0F0F] ">
                    <p className="">Drag & drop files or </p>
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-[#ff56d5] focus-within:outline-none  focus-within:ring-offset-2 hover:text-[#ff56d5]"
                    >
                      <span className="pl-1">Browse</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageUpload}
                        className="sr-only"
                      />
                    </label>
                  </div>
                  <p className="text-[12px] leading-5 text-[#676767]">
                    Supported formates: JPEG, PNG
                  </p>
                </div>
                {errorMessage && (
                  <p className="text-xs text-red-600 mb-2">{errorMessage}</p>
                )}
              </div>
              <div className="w-full md:w-[465px] lg:w-[465px]">
                <Button
                  // disabled={!isValid}
                  className={`block w-full ${!isValid && "opacity-50"}`}
                  label="Upload Files"
                  onClick={handleSubmit}
                />
              </div>
            </>
          )}
        </div>
        <div></div>
      </div>
    </section>
  );
};

export default UploadImageComponent;
