import React from "react";
import "./MobileNavbar.css";
import { Link } from "react-router-dom";
import { ReactComponent as WishList } from "../../Assets/Icon/wishlist.svg";
import { ReactComponent as Cart } from "../../Assets/Icon/cart.svg";
import { ReactComponent as HomePage } from "../../Assets/Icon/homepage.svg";
// import { ReactComponent as Home } from "../../Assets/Icon/home.svg";
import { ReactComponent as Home } from "../../Assets/Icon/homeSqr.svg";
import { ReactComponent as Profile } from "../../Assets/Icon/profile.svg";
// import { ReactComponent as WishList } from "../../Assets/Icon/wishlist.svg";

const MobileNavbar = () => {
  return (
    <div className="mobile-bottom-navbar flex justify-between">
      <Link
        to="/"
        className="flex flex-grow items-center justify-center hover:text-gray-200 text-white"
      >
        <HomePage height="19" width="19" fill="white" />
      </Link>
      <Link
        to="/wishlist"
        className="flex flex-grow items-center justify-center hover:text-gray-200 text-white"
      >
        <WishList height="24" width="24" />
      </Link>

      <Link
        to="/selected-product"
        className="flex items-center w-[118px] justify-center hover:text-gray-200 text-white"
      >
        <div className="absolute bottom-[18px]">
          <Home />
        </div>
      </Link>
      <Link
        to="/cart"
        className="flex items-center flex-grow justify-center hover:text-gray-200 text-white"
      >
        <Cart height="24" width="24" />
        <span className="flex absolute -mt-5 ml-4">
          <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-pink-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-pink-500"></span>
        </span>
      </Link>
      <Link
        to="/login"
        className="flex items-center flex-grow justify-center hover:text-gray-200 text-white"
      >
        <Profile height="24" width="24" />
      </Link>
    </div>
  );
};

export default MobileNavbar;
