import React from "react";
import PropTypes from "prop-types";

const Button = ({ onClick, type = "button", label,className, ...props }) => {
  return (
    <button
      type={type}
      className={`relative bg-gradient-to-l p-3 px-4 from-pink-500 to-purple-500 rounded-lg font-medium hover:bg-opacity-80 text-white transform transition-transform duration-500 focus:outline-none ${className}`}
      onClick={onClick}
      {...props}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default Button;
