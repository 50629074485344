import React, { useEffect, useRef } from "react";
import Navbar from "../Components/Navbar";
import SingleProductCard from "../Components/SingleProductCard";
import img from "../Assets/pngegg (5) 1.png";
import img2 from "../Assets/pngegg (5) 2.png";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { useMain } from "../hooks/useMain";
import { useState } from "react";
import Category from "./Category";
import OutsideClickHandler from "react-outside-click-handler";
import Loader from "../common/Loader";

function Products() {
  const { getProducts, getFilteredProducts, getTopCategories, searchProduct } =
    useMain();
  const [data, setData] = useState([]);
  const [loadFlag, setLoadFlag] = useState(true);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [priceShown, setPriceShown] = useState(true);
  const [priceFilter, setPriceFilter] = useState({ minPrice: 0, maxPrice: 0 });
  const [filterQuery, setFilterQuery] = useState({
    categories: [],
    minPrice: 0,
    maxPrice: null,
    colors: [],
    sortBy: "latest",
    product_subCategory: [],
  });
  const [allCategoryShown, setAllCategoryShown] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [value, setValue] = useState("");
  const [searchBar, setSearchBar] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const navFilterHandle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const filterRef = useRef(null);

  useEffect(() => {
    getData();
  }, [refreshFlag]);

  const getData = async () => {
    setLoadFlag(true);
    let ans = await getProducts();
    console.log(ans);
    setData(ans?.data?.products);
    setLoadFlag(false);

    const res3 = await getTopCategories();
    setCategoryList(res3?.data?.categories);
  };

  const handlePriceChange = (value) => {
    const newPriceFilter = {
      minPrice: 0,
      maxPrice: parseInt(value),
    };
    setPriceFilter(newPriceFilter);
  };

  const handleCategoryCheck = (_id) => {
    const newList = categoryList.map((item) => {
      if (item._id === _id) return { ...item, isChecked: !item.isChecked };
      else return item;
    });
    setCategoryList(newList);
  };

  const handleSubCategoryCheck = (_id, name, subitem) => {
    console.log(subCategoryList);
    if (subCategoryList?.includes(subitem)) {
      setSubCategoryList(subCategoryList?.filter((i) => i !== subitem));
    } else {
      setSubCategoryList([...subCategoryList, subitem]);
    }
  };

  const handleFilterSubmit = async () => {
    setLoadFlag(true);
    const newFilterQuery = { ...filterQuery };

    const categoriesQuery = categoryList
      .filter((item) => item.isChecked)
      .map((item) => item._id);

    if (categoriesQuery.length !== 0)
      newFilterQuery.categories = categoriesQuery;
    else delete newFilterQuery.categories;

    delete newFilterQuery.colors;

    if (priceFilter.maxPrice) newFilterQuery.maxPrice = priceFilter.maxPrice;
    else delete newFilterQuery.maxPrice;

    if (subCategoryList.length > 0)
      newFilterQuery.product_subCategory = subCategoryList;
    else delete newFilterQuery.product_subCategory;

    newFilterQuery.minPrice = priceFilter.minPrice;

    setFilterQuery(newFilterQuery);

    try {
      const result = await getFilteredProducts(newFilterQuery);
      if (result?.status === "success") {
        if (result.data.products.length === 0) alert("No products found.");
        setData(result.data.products);
      } else alert(result.error.message);
      setLoadFlag(false);
    } catch (err) {
      console.log(err);
      alert("Internal server error.");
      setLoadFlag(false);
    }
  };

  return (
    <>
      <div className="prod0">
        <div className={`prod01 ${isNavOpen ? "prod01-active" : ""}`}>
          <div
            id="filter-container"
            className="relative overflow-y-auto"
            ref={filterRef}
          >
            <div className="w-full">
              <div
                className="w-full flex justify-between items-center font-semibold text-[14px] sm:text-[16px] mt-1 cursor-pointer mb-3"
                // onClick={() => setAllCategoryShown(!allCategoryShown)}
              >
                Categories
                {/* {allCategoryShown ? (
                  <span className="text-[40px] mx-2">-</span>
                ) : (
                  <span className="text-[27px] mx-2">+</span>
                )} */}
                <div className={`block sm:hidden`} onClick={() => navFilterHandle()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    id="close"
                    height="20"
                    width="20"
                  >
                    <path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                  </svg>
                </div>
              </div>

              {allCategoryShown && (
                <>
                  <ul className="w-full flex flex-col pl-9">
                    {categoryList.map((item) => (
                      // <li className="my-1 flex items-center gap-2" key={item._id}>
                      //     <input
                      //         type="checkbox"
                      //         className="cursor-pointer"
                      //         checked={item.isChecked}
                      //         onChange={() => handleCategoryCheck(item._id)}
                      //     />
                      //     {item?.name}
                      // </li>
                      <Category
                        handleSubCategoryCheck={handleSubCategoryCheck}
                        handleCategoryCheck={handleCategoryCheck}
                        subCategoryList={subCategoryList}
                        setSubCategoryList={setSubCategoryList}
                        item={item}
                        key={item?._id}
                      />
                    ))}
                  </ul>
                </>
              )}
            </div>

            <hr className="mt-2" />
            <div className="w-full ">
              <div
                className="w-full font-semibold flex justify-between items-center text-[14px] sm:text-[16px] mt-1 cursor-pointer"
                // onClick={() => setPriceShown(!priceShown)}
              >
                Price
                {/* {priceShown ? (
                  <span className="text-[40px] mx-2">-</span>
                ) : (
                  <span className="text-[27px] mx-2">+</span>
                )} */}
              </div>

              {priceShown && (
                <>
                  <input
                    className="w-full h-1"
                    type="range"
                    min="0"
                    max="15000"
                    onChange={(e) => handlePriceChange(e.target.value)}
                  />
                  <p className="text-slate-400 mt-3 text-[8px] sm:text-[14px] flex justify-between items-center">
                    <span>RS.0</span>
                    <span>RS.{priceFilter.maxPrice}</span>
                  </p>
                </>
              )}
            </div>

            <button
              className="buttonDesginer filter-btn cursor-pointer"
              onClick={handleFilterSubmit}
            >
              FILTER
            </button>
          </div>
        </div>

        <div className="prod02">
          <div className={`prod021`} onClick={() => navFilterHandle()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="29"
              fill="currentColor"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </div>

          <OutsideClickHandler
            onOutsideClick={() => {
              setSearchBar(false);
            }}
          >
            <div className="nav-in nav-in2 ">
              <form
                className="relative h-full"
                onSubmit={async () => {
                  let ans = await searchProduct(value);
                  console.log(ans);
                  setSearchData(ans.data);
                  if (ans.data.length > 0) {
                    setSearchBar(true);
                  } else {
                    setSearchBar(false);
                  }
                }}
              >
                <input
                  type="text"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi cursor-pointer bi-search absolute right-4 top-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </form>

              {searchBar && (
                <div className="search-list">
                  {searchData?.map((element, index) => {
                    return (
                      <>
                        <Link className="m-0" to={`/product/${element._id}`}>
                          <div
                            key={index}
                            className="flex py-1"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <img
                              src={element.displayImage[0].url}
                              style={{ height: "30px", width: "30px" }}
                              alt=""
                            />
                            <h6 className="pl-2">{element.displayName}</h6>
                          </div>
                        </Link>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </OutsideClickHandler>

          {loadFlag ? (
            <Loader />
          ) : (
            <div className="allproducts">
              <div className="filters">{/* <input/> */}</div>

              <img className="thd" src={img} />
              <img className="thd1" src={img2} />

              <div className="container">
                <div className="prod-main">
                  {data.map((e, index) => {
                    return (
                      <SingleProductCard
                        data={e}
                        refreshFlag={refreshFlag}
                        setRefreshFlag={setRefreshFlag}
                      />
                    );
                  })}
                </div>
              </div>

              {/* <div className="product-section">
          <Link className="link" to="/product">
            <SingleProductCard />
          </Link>
          <Link className="link" to="/product">
            <SingleProductCard />
          </Link>
          <Link className="link" to="/product">
            <SingleProductCard />
          </Link>
        </div> */}
            </div>
          )}
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
}

export default Products;
