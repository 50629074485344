import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Home from "./Pages/Home";
import Products from "./Pages/Products";
import Cart from "./Pages/Cart";
import Product from "./Pages/Product";
import ProfilePage from "./Pages/ProfilePage";
import MyOrder from "./Pages/MyOrder";
import AboutUs from "./Pages/AboutUs";
import Login from "./Pages/Login";
import SingUp from "./Pages/SingUp";
import WishList from "./Pages/WishList";
import MainState from "./context/MainState";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Layout from "./Components/Layout/Layout";
import NotFound from "./Pages/NotFound";
import UploadImageComponent from "./Pages/UploadImage";
import GenratedSticker from "./Pages/GenratedSticker";

function App() {
  return (
    <>
      <MainState>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/selected-product" element={<UploadImageComponent />} />
            <Route path="/selected-product/:id" element={<GenratedSticker />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/myorder" element={<MyOrder />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/wishlist" element={<WishList />} />

            <Route
              path="/login"
              element={
                <GoogleOAuthProvider clientId="579189163975-pmnvifuo42fgmnc4rvf3p0mrit945ius.apps.googleusercontent.com">
                  <Login />
                </GoogleOAuthProvider>
              }
            />
            <Route path="/singup" element={<SingUp />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainState>
    </>
  );
}

export default App;
