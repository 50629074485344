import React from 'react'
import img from '../Assets/Rectangle 37.png'
import { Link } from 'react-router-dom';

function ArrivelCards({ item }) {

   return (
      <>
         <Link className='arrivecard' to={`/product/${item._id}`}>
            <div className='arrivecard-img'>
               <img className='arr-img' src={item.displayImage[0].url} />
            </div>
            <br />
            <div className='arrivecard-info'>
               <div className='arrivecard-title'>
                  <p className='tname'>{item.displayName}</p>
                  <p className='tprise'>${item.price}</p>
               </div>
               {/* <p className='tdesc'>{item.description}</p> */}
            </div>
         </Link>
      </>
   );
};

export default ArrivelCards;
