import React, { useEffect, useLayoutEffect, useState } from "react";
import "./Loader.css";

const GenrateLoader = () => {
  const [currentCount, setCount] = useState(1);

  useEffect(() => {
    if (currentCount <= 0) {
      return;
    }

    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [currentCount]);

  const timer = () => setCount(currentCount + 1);

  return (
    <div className="flex bg-[#2b2c2f] rounded h-[280px] md:h-[250px] items-center justify-center">
      <div>
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
        <h6 className="text-xl font-mono font-medium text-[#ad55f1] mt-3">
          {currentCount}
        </h6>
      </div>
    </div>
  );
};

export default GenrateLoader;
