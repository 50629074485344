import React from "react";
import img from "../Assets/Rectangle 3.png";

function Banner() {
  return (
    <>
      <div className="banner ">
        <div className="banner-left">
          <h1>
            ELEVATE YOUR STYLE,
            <br /> EMBRACE YOUR
            <br /> ANIME SPIRIT.
          </h1>
          <p className="mb-3">
            Discover a curated collection of anime-inspired designs
            <br /> that capture the essence of your favorite characters and
            <br /> series.
          </p>
          <div className="flex items-center gap-1">
            <button className="discovernow buttonDesginer">Discover now</button>
          </div>
        </div>
      </div>

      <div className="explore-section">
        {/* <div className='exp'>
        <div className='exp-btn'>
        <div className='inner-btn'>
<h1>hii</h1>
        </div>

        </div>

        </div> */}
        {/* <img src={img}/> */}
        {/* 
        <div className='expbtn'>
<p>Explore Now</p>
        </div> */}
      </div>
    </>
  );
}

export default Banner;
