import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Rectangle 38.png'
import img2 from '../Assets/Layer_x0020_1.png'



function MyOrder() {
  return (
    <>
      <div>
        <div className='newarrivel'>
          <div className='newarrivel-left'>
            <p>-My</p>

            <h1>Order</h1><img className='ext' src={img2} />



          </div>
          <div className='newarrivel-right'>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br /> sed do eiusmod tempor incididunt ut labore et dolore<br /> magna aliqua.</p>

          </div>


        </div>

      </div>

      <div className='orders'>
        <div className="box">
          <img src={img} />
          <div className="content">
            <h3>Title Name</h3>    <h4 className='date'>23 august 2023</h4>


            <h4>Price: $40</h4>


            <p className="unit">Quantity: <input name="" value="2" /></p>
            <p className="btn-area"> <span className="btn2">Re-order</span></p>

          </div>
        </div>
        <div className="box">
          <img src={img} />
          <div className="content">
            <h3>Title Name</h3>    <h4 className='date'>23 august 2023</h4>


            <h4>Price: $40</h4>


            <p className="unit">Quantity: <input name="" value="2" /></p>
            <p className="btn-area"> <span className="btn2">Re-order</span></p>

          </div>
        </div>


      </div>
      <Footer />
    </>
  )
}

export default MyOrder