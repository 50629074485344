import React from 'react'
import img from '../Assets/Rectangle 38.png'
import { baseUrl } from '../context/MainState';
import axios from 'axios';
import { Link } from 'react-router-dom';

function SingleProductCard({ data, setRefreshFlag, refreshFlag }) {
  var login = localStorage.getItem('glitch-token');
  var user = localStorage.getItem('glitch-user');
  if (user) {
    user = JSON.parse(user);
  }

  async function AddWishList(productId, product, e) {
    // if(isLogin === true) {
    if (login) {
      try {
        // let url;
        // if (flag === "false") {
        //   url = ;
        // } else {
        //   url = `${baseUrl}/api/wishlist/product/${productId}/subtract`;
        // }

        const res = await axios({
          method: "post",
          url: `${baseUrl}/api/wishlist/product/${productId}/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('glitch-token')}`,
          },
        });

        if (res.data.status === "success") {
          setRefreshFlag(!refreshFlag);
          alert(res.data.data.message);
        } else {
          alert(res.data.data.error);
        }
      } catch (e) { }
    } else {
      alert("Please login");
    }
  }

  async function RemoveWishList(productId, product) {
    if (login) {
      try {
        // let url;
        // if (flag === "false") {
        //   url = ;
        // } else {
        //   url = `${baseUrl}/api/wishlist/product/${productId}/subtract`;
        // }

        const res = await axios({
          method: "post",
          url: `${baseUrl}/api/wishlist/product/${productId}/subtract`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('glitch-token')}`,
          },
        });

        if (res.data.status === "success") {
          setRefreshFlag(!refreshFlag);
          alert(res.data.data.message);
        } else {
          alert(res.data.data.error);
        }
      } catch (e) { }

    } else {
      // Login
      alert("Please login");
    }
  }

  return (
    <>
      <div className='pro-card'>
        <Link className="link" to={`/product/${data._id}`}>
          <div className='pro-card-img'>
            <img className='product-card-proimg' src={data?.displayImage[0]?.url} />
          </div>
        </Link>
        <div className="flex wish">
          <Link className="link" to={`/product/${data._id}`}>
            <h1>{data?.displayName}</h1>
          </Link>
          <div className='cursor-pointer'>
            {user ? data.isWishlist.includes(user._id) ?
              <svg
                onClick={() => RemoveWishList(data?._id, data)}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-heart-fill text-red-600"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                />
              </svg>
              :
              <svg
                onClick={() => AddWishList(data?._id, data)}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-heart"
                viewBox="0 0 16 16"
              >
                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
              </svg>
              :
              <svg
                onClick={(e) => AddWishList(data?._id, data, e)}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-heart"
                viewBox="0 0 16 16"
              >
                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
              </svg>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProductCard;
