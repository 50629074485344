import React from 'react'
import img from '../Assets/Group 30.png'
import p1 from '../Assets/profile.jpg'
import p2 from '../Assets/Ellipse 120.png'
import p3 from '../Assets/Ellipse 121.png'
import p4 from '../Assets/Ellipse 122.png'
import p5 from '../Assets/Ellipse 123.png'


function Testimonials() {
  return (
    <>
      <div className='Testimonials'>
        <div className='Testimonials-left'>
          <p className='test-heading'>- Testimonials</p>
          <h1>Our Best Seller</h1>
          <h1 className="flex items-center">Products <img className='ourbest' src={img} /></h1>
          <p className='test-des'>Lorem ipsum dolor sit amet, consectetur<br />
            adipiscing elit, sed do eiusmod tempor<br />
            incididunt ut labore et dolore magna aliqua.</p>
          <div className="flex items-center mt-2">
          <button className='discovernow buttonDesginer'>Explore Product</button>
          </div>
        </div>
        <div className='Testimonials-right'>
          <div className='testimonial-imgs'>

            <img src={p2} />
            <img src={p3} />
            <img src={p1} />
            <img src={p4} />
            <img src={p5} />


          </div>
          <p className='username'>Shane Lee</p>
          <p className='work'>Student, Digital Marketing</p>

          <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
          <p className='comment'>Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br /> sed do eiusmod tempor incididunt ut labore et dolore <br />magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
        </div>

      </div>
    </>
  )
}

export default Testimonials