import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useMain } from '../hooks/useMain';

function SingUp() {
  const { signup } = useMain();
  const navigate = useNavigate();

  const [value, setValue] = useState({
    displayName: '', email: '', password: '', phoneNumber: ''
  });

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let ans = await signup(value);
    console.log(ans);
    if (ans.status) {
      alert('signup success');
      navigate('/login');
    }
    else {
      alert('signup fail');
    }
  }

  return (
    <>
      <div className='LoginPage'>
        <div className='LoginPages'>
          <form onSubmit={handleSubmit} className='login-form'>
            <h1>Sign Up</h1>
            <input value={value.displayName} onChange={handleChange} name="displayName" placeholder='Username' />
            <input value={value.email} onChange={handleChange} name="email" placeholder='Email ID' />
            <input value={value.phoneNumber} onChange={handleChange} name="phoneNumber" placeholder='Phone No.' />
            <input value={value.password} onChange={handleChange} name="password" placeholder='Password' />
            <button type='submit'>Create an new account</button>
            <p>Already have an account<span><Link to='/login'>Login</Link>  </span></p>
          </form>
        </div>
      </div>
    </>
  );
};

export default SingUp;
