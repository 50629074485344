import React from "react";
import Navbar from "../Navbar";
import { Outlet } from "react-router-dom";
import MobileNavbar from "../MobileNavigation/MobileNavbar";
import { useWindowSize } from "../../common/useWindowResize";

const Layout = () => {
  const window = useWindowSize();

  return (
    <>
      <Navbar />
      <Outlet />

      {window.width <= 768 && <MobileNavbar />}
    </>
  );
};

export default Layout;
