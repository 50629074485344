import React, { useEffect, useState } from 'react'
import ProductCard from './ProductCard'
import img from '../Assets/pngegg (5) 1.png'
import img2 from '../Assets/pngegg (5) 2.png'
import img3 from '../Assets/image-removebg-preview (98) 1.png'
import img4 from '../Assets/pngegg (3) 1.png'
import { useMain } from '../hooks/useMain'

function BestSellerCard() {
  const { getProducts1, getFilteredProducts } = useMain();
  const [data, setData] = useState([]);
  const [loadFlag, setLoadFlag] = useState(true);
  const [refreshFlag, setRefreshFlag] = useState(false)

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoadFlag(true);
    let ans = await getProducts1();
    console.log(ans);
    setData(ans?.data?.products);
    setLoadFlag(false);
  };

  return (
    <>
      {/* <div className='scroll-container'>
        <div className='cat-btns'>
          <div className='tab'>
            All
          </div>
          <div className='tab'>
            T-shirt
          </div>
          <div className='tab'>
            T-shirt
          </div>
          <div className='tab'>
            T-shirt
          </div>
          <div className='tab'>
            T-shirt
          </div>
          <div className='tab'>
            T-shirt
          </div>
          <div className='tab'>
            T-shirt
          </div>
          <div className='tab'>
            T-shirt
          </div>
        </div>
      </div> */}

      <div className='scroll-container'>
        <div className='products'>
          {data.map((e, index) => {
            return (
              <ProductCard key={index} item={e} />
            );
          })}
        </div>
      </div>

      {/* <div className='scroll-container'>
        <div className='products'>
          <ProductCard />
          <ProductCard />
          <ProductCard />
        </div>
      </div> */}

      {/* <img className='thunder-left' src={img}/> */}
      {/* <img className='thunder-right' src={img2}/> */}
      {/* <img className='anime-left' src={img3}/> */}
      {/* <img className='anime-right' src={img4}/> */}
    </>
  )
}

export default BestSellerCard