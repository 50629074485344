import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMain } from '../hooks/useMain';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, login1 } = useMain();

  const handleLogin = async () => {
    let ans = await login({ email: username, password });
    console.log(ans);

    if (ans.status === "success") {
      localStorage.setItem('glitch-token', ans.data.user.token);
      localStorage.setItem('glitch-user', JSON.stringify(ans.data.user));
      alert('Login successful');
      navigate('/');
    } else {
      setError('Invalid username or password');
    }
  };

  const handleLogin1 = async (email, displayName) => {
    let ans = await login1({ email, displayName });
    console.log(ans);

    if (ans.status === "success") {
      localStorage.setItem('glitch-token', ans.data.user.token);
      localStorage.setItem('glitch-user', JSON.stringify(ans.data.user));
      alert('Login successful');
      navigate('/');
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <>
      <div className='LoginPage'>
        <div className='LoginPages'>
          <div className='login-form'>
            <h1>Login</h1>
            <input type='email' placeholder='Email' value={username}
              onChange={(e) => setUsername(e.target.value)} />
            <input placeholder='Password' type='password' value={password}
              onChange={(e) => setPassword(e.target.value)} />
            <button onClick={handleLogin}>Login</button>

            <GoogleLogin
              onSuccess={credentialResponse => {
                // console.log(credentialResponse);
                console.log("The creds for google are :-")
                console.log(credentialResponse.credential)
                var decoded = jwt_decode(credentialResponse.credential);
                console.log("THe decoded data is:")
                console.log(decoded);
                handleLogin1(decoded.email, decoded.name);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />

            {error && <p className="error">{error}</p>}
            <p>Don't have account<span><Link to='/singup'>sign in</Link>  </span></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login