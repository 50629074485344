import React from 'react'
import img from '../Assets/Group 30.png'
function BestSeller() {
  return (
    <>
      <div className='newarrivel'>
        <div className='newarrivel-left'>
          <p>-Best Seller</p>
          <h1>Our Best Seller</h1>
          {/* <h1>Products </h1> */}
          <h1 className="flex items-center">Products <img className='ourbest' src={img} /></h1>



        </div>
        <div className='newarrivel-right'>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br /> sed do eiusmod tempor incididunt ut labore et dolore<br /> magna aliqua.</p>

        </div>


      </div>
    </>
  )
}

export default BestSeller