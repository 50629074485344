import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Rectangle 51.png'
import { useState } from 'react'
import { useMain } from '../hooks/useMain'

function Cart() {
  var login = localStorage.getItem('glitch-token');

  const { getAllCarts, modifyUserCart } = useMain();

  const [carts, setCarts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loadFlag, setLoadFlag] = useState(true);

  const getCarts = async () => {
    setLoadFlag(true);
    const res = await getAllCarts();
    console.log(res?.data?.cart?.products);

    setCarts(res?.data?.cart?.products);

    setTotalPrice(
      res?.data?.cart?.products?.reduce(
        (accumulator, current) =>
          current?.product
            ? accumulator +
            current?.quantity * (current.variant !== "" ? current?.product?.priceVarient?.find(
              (x) => x.varient === current.variant
            ).price : current?.product?.priceVarient[0].price)
            : accumulator + 0,
        0
      )
    );

    setLoadFlag(false);
  };

  useEffect(() => {
    if (login) {
      getCarts();
    }
    else
    {
      setLoadFlag(false);
    }
  }, []);

  const modifyCart = async (productId, type, product) => {
    setLoadFlag(true);

    if (login) {
      const res = await modifyUserCart({ productId, type });
      // console.log(res);
      setCarts(res?.data?.cart?.products);

      // setTotalPrice(
      //   res?.data?.cart?.products?.reduce(
      //     (accumulator, current) =>
      //       current?.product
      //         ? accumulator + current?.quantity * current?.product?.price
      //         : accumulator + 0,
      //     0
      //   )
      // );

      setTotalPrice(
        res?.data?.cart?.products?.reduce(
          (accumulator, current) =>
            current?.product
              ? accumulator +
              current?.quantity * (current.variant !== "" ? current?.product?.priceVarient?.find(
                (x) => x.varient === current.variant
              ).price : current?.product?.priceVarient[0].price)
              : accumulator + 0,
          0
        )
      );
    }

    setLoadFlag(false);
  };

  // const handleOrder = async () => {
  //   setLoadFlag(true);

  //   if (totalPrice > 0) {
  //     let user=localStorage.getItem('glitch-user');
  //     // no address navigate profile

  //     let orderAmt = (totalPrice - discountPrice)?.toFixed(2);

  //     const userAddress = user?.shippingAddress.filter(
  //       (item) => item?.isDefault
  //     );
  //     // console.log(userAddress[0].address.split(',')[3]);
  //     // console.log(userAddress[0].address);

  //     var cartFilter = carts.filter((e) => e.product !== null);
  //     // console.log(cartFilter)
  //     const createOrder = {
  //       products: cartFilter,
  //       coupon_applied: null,
  //       shippingAddress: userAddress,
  //       order_price: orderAmt,
  //     };

  //     // alert("1");
  //     const savedOrder = await fetchCreateCC_Order(createOrder);
  //     // console.log(savedOrder);

  //     // console.log(userAddress);

  //     let ccOrder_data = {
  //       merchant_id: "2513570",
  //       access_code: "AVIK77KF61BD04KIDB",
  //       amount: orderAmt.toString(),
  //       billing_name: user?.displayName,
  //       billing_email: user?.email,
  //       billing_city: userAddress[0].address.split(',')[4].split(":")[1] ? userAddress[0].address.split(',')[4].split(":")[1] : userAddress[0].address.split(',')[4].trim(),
  //       billing_state: userAddress[0].address.split(',')[3].split(":")[1] ? userAddress[0].address.split(',')[3].split(":")[1] : userAddress[0].address.split(',')[3].trim(),
  //       billing_country:"India",
  //       billing_address: userAddress[0].address,
  //       billing_zip: userAddress[0].pincode,
  //       billing_tel: user?.phoneNumber,
  //       delivery_name: user?.displayName,
  //       delivery_address: userAddress[0].address,
  //       delivery_zip: userAddress[0].pincode,
  //       delivery_tel: user?.phoneNumber,
  //       order_id: savedOrder?.data?.order?._id,
  //       currency: "INR",
  //       // redirect_url: `https://www.aaradhak.com/thank`,
  //       // cancel_url: `https://www.aaradhak.com/failed`,
  //       // redirect_url: `http://localhost:8080/ccavenueresponsehandler`,
  //       // cancel_url: `http://localhost:8080/ccavenueresponsehandler`,
  //       redirect_url: `${baseUrl}/ccavenueresponsehandler`,
  //       cancel_url: `${baseUrl}/ccavenueresponsehandler`,
  //       language: "EN",
  //     };

  //     console.log({ ccOrder_data, user });

  //     let body = new URLSearchParams();

  //     Promise.all(
  //       Object.keys(ccOrder_data).map((key) =>
  //         body.append(key, ccOrder_data[key])
  //       )
  //     );

  //     // console.log(body);

  //     const res = await cc_Order(body);

  //     window.location.href = res;
  //     setOrderLoading(false);

  //     setCouponCode("");
  //     setValidCoupon(false);
  //     // toast.success("Coming soon...");
  //   }
  // };

  return (
    <>

      {loadFlag ? 'Loading ..' : <div className="project">
        <div className="shop">
          {carts.length > 0 ? carts?.map?.((e, index) => {
            return (
              <div key={index} className="box box1">
                <img src={e?.product?.displayImage[0].url} />
                <div className="content">
                  <h3>{e?.product?.displayName} ({e?.variant})</h3>
                  <h4>Price: ${e.variant !== "" ? e?.product?.priceVarient?.find(x => x?.varient === e?.variant)?.price * e?.quantity : e?.product?.priceVarient[0]?.price * e?.quantity}</h4>
                  <div className="flex cart-1">
                    <p className="unit">Quantity: <input name="" value={e?.quantity} /></p>
                    {/* <p className="btn-area"><i aria-hidden="true" className="bi bi-trash-fill"></i> <span className="btn2">Remove</span></p> */}
                    <div className="flex">
                      <div className='cart-11' onClick={() => {
                        modifyCart(e?.product?._id, "subtract", e.product);
                      }}>
                        -
                      </div>
                      <div className='cart-12' onClick={() => {
                        modifyCart(e?.product?._id, "add", e.product);
                      }}>
                        +
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }) : <h2 style={{width: 'fit-content', margin: '10rem auto'}}>No items added</h2>}
        </div>
        {carts.length > 0 && <div className="right-bar">
          <p><span>Subtotal</span> <span>${totalPrice}</span></p>
          <hr />
          <p><span>Tax (5%)</span> <span>${(totalPrice * 0.05).toFixed(2)}</span></p>
          <hr />
          <p><span>Shipping</span> <span>$15</span></p>
          <hr />
          <p><span>Total</span> <span>${(totalPrice + (totalPrice * 0.05) + 15).toFixed(2)}</span></p><a href="#"><i className="fa fa-shopping-cart "></i>Checkout</a>
        </div>}
      </div>}
      <br />

      <Footer />

    </>
  )
}

export default Cart