import React from 'react'
import img from '../Assets/Group 30.png'
import img2 from '../Assets/05 1.png'

function Arrivel() {
  return (
    <>
      <div className='newarrivel'>
        <div className='newarrivel-left'>
          <p>-New</p>
          <h1 className="flex items-center">Our Best <img className='ourbest' src={img} /></h1>
          <h1>New Arrivel</h1>



        </div>
        <div className='newarrivel-right'>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore<br /> magna aliqua.</p>
          {/* <img className='bang' src={img2}/> */}
        </div>


      </div>
    </>
  )
}

export default Arrivel