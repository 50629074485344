import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/about.jpg'
import img2 from '../Assets/pngegg (4) 1.png'


function AboutUs() {
  return (
    <>
      <div className='aboutus'>
        <div className='ab'>
          {/* <img className='abt' src={img}/> */}
          <div className='ab1'>
            <h1>About Us</h1>
          </div>
        </div>


        <div className='aboutsecond'>
          <div className='aboutsecond-left'>
            <img className='aboutimg' src={img} />


          </div>
          <div className='aboutsecond-right'>
            <div className='newarrivel-left'>
              <p>- About Us</p>
              <h1>Our Story <img className='ourbest' src={img2} /></h1>
              {/* <h1>New Arrivel</h1> */}
              <p className='about-p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore<br /> magna aliqua.</p>



            </div>

          </div>



        </div>

      </div>
      <Footer />
    </>
  )
}

export default AboutUs