const initialState = {
    data: {},
    loading: false,
    success: false,
    error: false,
  };
  


  const genarateImageSticker = (state = initialState, action) => {
    switch (action.type) {
      case "POST_GENARATE_LOADING":
        return {
          ...state,
          data: {},
          loading: true,
          success: false,
          error: false,
        };
      case "POST_GENARATE_SUCCESS":
        return {
          ...state,
          data: action.payload,
          loading: false,
          success: true,
          error: false,
        };
      case "POST_GENARATE_ERROR":
        return {
          ...state,
          data: {},
          loading: false,
          success: false,
          error: true,
        };
      default:
        return state;
    }
  };
  
  export default genarateImageSticker;
  