import React, { useEffect, useRef, useState } from "react";
import "./GenratedSticker.css";
import {  useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import placeholder from "../Assets/image.png";
import { ReactComponent as Download } from "../Assets/Icon/download.svg";
import { ReactComponent as RefreshIcon } from "../Assets/Icon//refresh.svg";
import { ReactComponent as MirrorImage } from "../Assets/Icon//mirror.svg";
import {
  GetImageSticker,
  ResetUploadImageSticker,
  genarateImageSticker,
} from "../store/action/apiActions";
import Button from "../common/button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import "./styles.css";
import { useWindowSize } from "../common/useWindowResize";
import GenrateLoader from "../common/GenrateLoader";

const GenratedSticker = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const window = useWindowSize();
  const [mirror, setMirror] = useState(false);
  const params = useParams();
  const { getData } = useSelector((state) => {
    return {
      getData: state.getImageData,
    };
  });

  const changeImageHandle = () => {
    navigate("/selected-product");
    dispatch(ResetUploadImageSticker());
  };
  const mirrorButtonPress = () => {
    setMirror(true);
  };
  const handleButtonRelease = () => {
    setMirror(false);
  };
  const handleButtonMobile = () => {
    setMirror(!mirror);
  };
  let urlRegenImg = `https://glitch-backend-node-9la3v.ondigitalocean.app/user/test/refreshSticker`;
  // let urlRegenImg = `http://localhost:8080/user/test/refreshSticker`;
  const RegenarateImageHandle = () => {
    let payload = {
      reqId: params?.id,
    };
    dispatch(genarateImageSticker(urlRegenImg, params?.id));
    setTimeout(() => {
      dispatch(GetImageSticker(url, payload));
    }, 3000);
    // setResponseData(true)
  };

  // const ImageDownloadHandle = async () => {
  //   const image = await imageRef.current.src;

  //   console.log(image);
  //   // //console.log(image, "asdasda");
  //   fetch(image)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(new Blob([blob]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "image.jpg"); // Set the desired filename
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading image:", error);
  //     });
  // };

  const ImageDownloadHandle = async () => {
    try {
      // Assuming imageRef is a React ref
      const imageElement = imageRef.current.src;

      const image = await fetch(imageElement);

      // Split image name
      const nameSplit = imageElement.split("/");
      const duplicateName = nameSplit.pop();

      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);
      const link = document.createElement("a");
      link.href = imageURL;
      link.download = "" + duplicateName + "";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error handling image download:", error);
    }
  };

  // console.log(params.id, getData, "prams");

  let url = `https://glitch-backend-node-9la3v.ondigitalocean.app/user/getSticker`;

  useEffect(() => {
    const requestBody = {
      reqId: params?.id,
    };

    if (params?.id) {
      dispatch(GetImageSticker(url, requestBody));
    }
  }, [dispatch, params?.id, url]);

  useEffect(() => {
    // Define a function to hit the API
    if (getData.data && getData.data.generationStatus === "processing") {
      let count = 0;
      const hitNextAPI = () => {
        if (count < 2) {
          // Replace this with your actual API request code for the second API
          const requestBody = {
            reqId: params?.id,
          };

          if (params?.id) {
            dispatch(GetImageSticker(url, requestBody));
          }

          count++;
          if (count < 2) {
            // Set a 10-second delay before hitting the next API
            setTimeout(hitNextAPI, 10000); // 10000 milliseconds = 10 seconds
          }
        }
      };

      // Start hitting the API with a 10-second delay
      setTimeout(hitNextAPI, 10000);
    }
    // Specify an empty dependency array to run this effect only once
  }, [getData.data.generationStatus === "processing"]);

  console.log(getData.data.generationStatus, "responseData");

  return (
    <div className="genrated-sticker-wrapper">
      <div className="left-panel">
        {window.width >= 769 ? (
          <div className="form-wrapper">
            <h6 className="text-[#ff56d5] mb-2 text-sm">Form Image</h6>
            <div className="flex flex-col items-center justify-center">
              <div className="mt-2 w-full Main-card-wrap">
                <div className="MainCard shadow rounded-lg overflow-hidden relative h-[170px] w-full">
                  <div className="overflow-hidden p-">
                    <img
                      src={getData.data.initialImage}
                      // src="https://u-static.fotor.com/images/text-to-image/result/PRO-6984dabdab4a4683b024246e00312ef0.jpg"
                      alt="upload"
                      className="mx-auto h-[170px] w-[187px] object-cover object-center overflow-hidden"
                    />
                  </div>
                  <div className="img-overlay hidden z-10 hover:backdrop-blur-sm h-full w-full hover:bg-[rgba(0,0,0,0.5)] absolute top-0 left-0">
                    <div className="flex items-center justify-center h-full">
                      <button
                        className="bg-white text-black text-xs font-bold rounded py-2 px-3"
                        type="button"
                        onClick={changeImageHandle}
                      >
                        Change Image
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {window.width >= 769 && (
          <div className="custom-style-wrapper">
            <h6 className="text-[#ff56d5] mb-3 text-sm">
              Choose a Custom Style
            </h6>

            <div className="flex md:grid grid-cols-3 gap-3">
              <div className="border cursor-pointer border-solid border-[#ff56d5] h-[100px] p-1 rounded">
                <img
                  src={
                    "https://pub-static.fotor.com/assets/aiImageConfig/template/nrivjkjce679.jpg"
                  }
                  className="rounded h-full w-full block"
                  alt=""
                />
              </div>
              {/* <div className="border cursor-pointer border-solid border-[#ff56d5] h-[100px] p-1 rounded">
              <img
                src="https://pub-static.fotor.com/assets/aiImageConfig/template/9t87jni6r92u.jpg"
                className="rounded h-full w-full block"
                alt=""
              />
            </div> */}
              <div className="border cursor-pointer border-solid border-[#ff56d5] h-[100px] p-1 rounded">
                <img
                  src="https://pub-static.fotor.com/assets/aiImageConfig/template/fhat6y5xjqjr.jpg"
                  className="rounded h-full w-full block"
                  alt=""
                />
              </div>
              <div className="border cursor-pointer border-solid border-[#ff56d5] h-[100px] p-1 rounded">
                <img
                  src="https://pub-static.fotor.com/assets/aiImageConfig/template/n3bql7rqlxby.jpg"
                  className="rounded h-full w-full block"
                  alt=""
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="main-center-panel">
        <div className="h-full w-full flex justify-center items-center p-4 bg-black">
          <div className="relative w-[280px] md:w-[250px] lg:w-[500px]">
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="mySwiper rounded"
            >
              {getData.data.generationStatus === "success" ? (
                getData.data.stickerUrls.map((item, key) => (
                  <SwiperSlide key={key}>
                    <div className="relative">
                      <img
                        ref={imageRef}
                        src={mirror ? getData.data.initialImage : item}
                        alt=""
                        className="h-auto w-full lg:h-[500px] lg:w-[500px] rounded"
                      />
                      {window.width > 768 ? (
                        <button
                          onMouseDown={mirrorButtonPress}
                          onMouseUp={handleButtonRelease}
                          type="button"
                          className="absolute p-2 bottom-2 right-2 backdrop-blur-md bg-slate-500 rounded-full flex items-center justify-center"
                        >
                          <MirrorImage className="pointer-events-none" />
                        </button>
                      ) : (
                        <button
                          onClick={handleButtonMobile}
                          type="button"
                          className="absolute p-2 bottom-2 right-2 backdrop-blur-md bg-slate-500 rounded-full flex items-center justify-center"
                        >
                          <MirrorImage className="pointer-events-none" />
                        </button>
                      )}
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide>
                  <GenrateLoader />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </div>
      </div>
      {window.width <= 769 && (
        <div className="form-wrapper">
          <div className="bg-black p-3 mb-2">
            <h6 className="text-[#ad55f1] mb-4 text-[12px]">
              Regenerate Image
            </h6>
            <div className="flex gap-3 items-center justify-between">
              <button
                disabled={getData.data.generationStatus === "processing"}
                type="button"
                onClick={RegenarateImageHandle}
              >
                <RefreshIcon stroke="#fff" />
              </button>
              <button
                type="button"
                className="border-none"
                onClick={ImageDownloadHandle}
              >
                <Download stroke="#fff" />
              </button>
            </div>
          </div>
          <h6 className="text-[#ff56d5] mb-4 text-sm">Orignal Image</h6>
          <div className="grid grid-cols-3 gap-3 items-center justify-center">
            <div className="flex-grow w-full Main-card-wrap">
              <div className="MainCard shadow rounded-lg overflow-hidden relative w-full">
                <div className="">
                  <img
                    src={getData.data.initialImage}
                    // src="https://u-static.fotor.com/images/text-to-image/result/PRO-6984dabdab4a4683b024246e00312ef0.jpg"
                    alt="upload"
                    className="mx-auto max-w-full object-cover object-center overflow-hidden"
                  />
                </div>
              </div>
            </div>
            <div className="MainCard shadow rounded-lg border border-dashed cursor-pointer border-[#ff56d5] h-full relative w-full">
              <div
                className="h-full flex items-center justify-center"
                onClick={changeImageHandle}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="#ff56d5"
                  class="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="right-panel relative">
        {window.width >= 769 && (
          <>
            <h6 className="text-[#ff56d5] text-xs px-4 py-2 border-b border-[#ffffff1a]">
              My Creation
            </h6>
            <div className="grid grid-cols-3 md:grid-cols-1 lg:grid-cols-2 gap-3 p-4">
              {getData.data?.stickerUrls &&
                getData.data.stickerUrls.map((item, key) => (
                  <div
                    key={key}
                    className="border cursor-pointer border-solid border-[#ff56d5] h-[100px] p-1 rounded"
                  >
                    <img
                      src={
                        getData.data.generationStatus === "processing"
                          ? placeholder
                          : item
                      }
                      className={`rounded h-full bg-white ${
                        getData.data.generationStatus === "processing" && "p-2"
                      } w-full block object-cover object-center`}
                      alt=""
                    />
                  </div>
                ))}
            </div>
          </>
        )}

        <div className="relative p-3 lg:p-0 lg:absolute lg:bottom-3 lg:right-3">
          <Button
            className="text-base md:text-xs font-bold rounded px-3 block w-full p-4 py-3"
            type="button"
            onClick={changeImageHandle}
            label="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default GenratedSticker;