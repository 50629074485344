import React, { useEffect, useState } from 'react'
import ArrivelCards from './ArrivelCards'
import { useMain } from '../hooks/useMain';

function ArrivelProduct() {
  const { getProducts, getFilteredProducts } = useMain();
  const [data, setData] = useState([]);
  const [loadFlag, setLoadFlag] = useState(true);
  const [refreshFlag, setRefreshFlag] = useState(false)

  useEffect(() => {
    getData();
  }, [refreshFlag]);

  const getData = async () => {
    setLoadFlag(true);
    let ans = await getProducts();
    console.log(ans);
    setData(ans?.data?.products);
    setLoadFlag(false);
  };
  

  return (
    <>
      <div className='scroll-container'>
        <div className='arrivelptoduts'>
          {data.map((e,index)=>{
            return (
              <ArrivelCards key={index} item={e} />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ArrivelProduct;
