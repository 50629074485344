import React from 'react';
import LoaderGif from "../Assets/EmKu.gif";

const Loader = () => {
  return (
    <div className='flex items-center justify-center h-[calc(100vh-103px)] w-full '>
        <img src={LoaderGif} alt="loader" className="h-20 w-20" />
    </div>
  )
}

export default Loader