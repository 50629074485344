import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Rectangle 38.png'
import img2 from '../Assets/pngegg (5) 2.png'
import img3 from '../Assets/pngegg (3) 1.png'
import img4 from '../Assets/pngegg (5) 1.png'
import { useNavigate, useParams } from 'react-router-dom'
import { useMain } from '../hooks/useMain'

function Product() {
    var login = localStorage.getItem('glitch-token');

    const { id } = useParams();
    const navigate=useNavigate();

    const { getProduct, addToCart, modifyUserCart } = useMain();

    const [data, setData] = useState({});
    const [loadFlag, setLoadFlag] = useState(true);
    const [variant, setVariant] = useState('');
    const [qty, setQty] = useState(1);
    const [activePriceVarient, setActivePriceVarient] = useState(null);
    const [altImg, setAltImg] = useState('');

    useEffect(() => {
        getData();
    }, [id]);

    const getData = async () => {
        setLoadFlag(true);
        let ans = await getProduct(id);
        console.log(ans);
        console.log(ans.data.product);
        setData(ans.data.product);
      
        setVariant(ans.data.product.priceVarient[0]);
        setLoadFlag(false);
    };

    const handleVarient = (e, index) => {
        document.querySelector('.active-variant').classList.remove('active-variant');
        document.querySelectorAll('.variant')[index].classList.add('active-variant');
        setVariant(e);
    };

    const handleCart = async () => {
        if (login) {
            let ans = await addToCart(id, 'add', variant);
            console.log(ans);
            if (ans.status === 'success') {
                alert('Added to cart');
            }
            else {
                alert('Something went wrong');
            }
        }
        else {
            alert('Please login');
        }
    };

    const BuyNow = async (id, product) => {
        // isLogin ? navigate("/cart") : navigate("/login");
        // isLogin ? navigate("/cart") : navigate("/register");
        setLoadFlag(true);

        if (login) {
            const res = await modifyUserCart({
                productId: id,
                type: "add",
                variant: variant,
            });

            console.log(res);

            if (res?.status === "success") {
                alert(res?.data?.message);
                navigate('/cart');
            } else {
                alert(res?.error?.message);
            }
        } else {
            navigate('/login');
        }

        setLoadFlag(false);
    };

    return (
        <>
            {loadFlag ? 'Loading ..' : <>
                <div className='productdetail'>
                    <div className="flex-box">
                        <div className="left">
                            <div className="big-img">
                                <img src={altImg!=='' ? altImg : data?.displayImage[0]?.url} />
                            </div>
                            <div className="images">
                                {data?.displayImage?.map((e, index) => {
                                    return (
                                        <div key={index} onClick={(r)=>{
                                            setAltImg(e.url);
                                            document.querySelector('.small-img-active')?.classList?.remove('small-img-active');
                                            r.target.parentNode.classList.add('small-img-active');
                                        }} className="small-img">
                                            <img src={e.url} />
                                        </div>
                                    );
                                })}

                                {/* <div className="small-img">
                                    <img src={img} />

                                </div>
                                <div className="small-img">
                                    <img src={img} />

                                </div>
                                <div className="small-img">
                                    <img src={img} />
                                </div> */}
                            </div>
                        </div>

                        <div className="right">
                            <div className="pname">{data?.displayName}</div>
                            <div className="ratings">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star-half-alt"></i>
                            </div>
                            <div className="price">${data?.price}</div>
                            <div className="size">
                                <p>Size :</p>
                                {data?.priceVarient?.map((e, index) => {
                                    return (
                                        <div key={index} className={index === 0 ? 'active-variant variant psize' : 'variant psize'} onClick={() => {
                                            console.log(e)
                                            
                                            handleVarient(e, index);
                                        }}>{e[0]} {e[1]}</div>
                                    );
                                })}

                                {/* <div className="psize">L</div>
                                <div className="psize">XL</div>
                                <div className="psize">XXL</div> */}
                            </div>
                            <div className="quantity">
                                <p>Quantity :</p>
                                <input type="number" min="1" max={data?.availability} onChange={(e) => {
                                    setQty(e.target.value);
                                }} value={qty} />
                            </div>
                            <div className="btn-box">
                                <button onClick={() => {
                                    handleCart();
                                }} className="cart-btn buttonDesginer">Add to Cart</button>
                                <button onClick={() => {
                                    BuyNow(data?._id, data);
                                }} className="buy-btn textGradient">Buy Now</button>
                            </div>
                            <br />
                            <div>
                                <h5>Description</h5>
                                <p dangerouslySetInnerHTML={{__html: data?.description}}></p>
                            </div>
                        </div>
                    </div>

                    <img className='pd-thunder' src={img2} />
                    <img className='pd-thunder2' src={img4} />
                    <img className='pd-thunder1' src={img3} />
                </div>
            </>}
            <Footer />
        </>
    )
}

export default Product