import React from 'react';
import img from '../Assets/Group 30.png';
import img1 from '../Assets/Layer_x0020_1.png';
import img2 from '../Assets/image-removebg-preview (97) 1.png';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

function Category() {
  return (
    <>
      <div className='category'>
        <Splide aria-label="My Favorite Images" options={{
          type: 'loop',
          autoplay: true,
          speed: 800
        }}>
          <SplideSlide>
            <div className="cat-main">
              <img src="/images/p1.png" alt="Image 1" />
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="cat-main">
              <img src="/images/p2.png" alt="Image 2" />
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </>
  );
};

export default Category;

{/* <div className='category'>
        <p>-Category</p>
        <h1>Discover Our Unique Collection<br /> of Exclusive Categories<img className='ourbest' src={img} /></h1>
        <div className='grid'>
          <div className='grid-left'>
            <div className='grid-left-top'>
              <div className='col1'>
                <p className='cat-name'>Hoodies</p>

              </div>
              <div className='col1'>
                <p className='cat-name'>Hoodies</p>

              </div>
            </div>
            <div className='grid-left-bottom'>
              <div className='col2'>

                <p className='cat-name1'>Hoodies</p>
              </div>

            </div>
          </div>
          <div className='grid-right'>
            <p className='cat-name2'>Hoodies</p>
          </div>
        </div>
      </div> */}
