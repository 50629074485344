export const POST_SUCCESS = "POST_SUCCESS";
export const POST_LOADING = "POST_LOADING";
export const POST_ERROR = "POST_ERROR";
export const POST_RESET = "POST_RESET";

export const POST_GENARATE_SUCCESS = "POST_GENARATE_SUCCESS";
export const POST_GENARATE_LOADING = "POST_GENARATE_LOADING";
export const POST_GENARATE_ERROR = "POST_GENARATE_ERROR";
export const POST_GENARATE_RESET = "POST_GENARATE_RESET";

export const GET_SUCCESS = "GET_SUCCESS";
export const GET_LOADING = "GET_LOADING";
export const GET_ERROR = "GET_ERROR";