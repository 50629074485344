// src/reducers/index.js
import { combineReducers } from 'redux';
import counterReducer from './reducer/counterReducer';
import urlReducer from './reducer/uploadReducer';
import cordinateReducer from './reducer/cordinateReducer';
import getImageReducer from './reducer/getImageSticker';
import genarateImageSticker from './reducer/genarateReducer';

const rootReducer = combineReducers({
  counter: counterReducer,
  UploadImage: urlReducer,
  getImageData: getImageReducer,
  genarateImage: genarateImageSticker,
  cordinate: cordinateReducer,
});

export default rootReducer;
