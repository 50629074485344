import React from 'react'
import img from '../Assets/Rectangle 59.png'
import { Link } from 'react-router-dom';

function ProductCard({ item }) {
  return (
    <>
      <Link to={`/product/${item._id}`} className='card'>
        <div className='card-img'>
          <img src={item.displayImage[0].url} />
        </div>
        
        <div className='card-info'>
          <div className='tit-pri'>
            <p className='tname'>{item.displayName}</p>
            <p className='tprise'>${item.price}</p>
          </div>
          {/* <p className='tdesc'>{item.description}</p> */}
        </div>
      </Link>
    </>
  );
};

export default ProductCard;
