import React, { useState } from 'react'

const Category = ({ item, handleCategoryCheck, subCategoryList, setSubCategoryList, handleSubCategoryCheck }) => {
    const [subCategoryOpen, setSubCategoryOpen] = useState(false);
    return (
        <div>
            <li className="my-1 flex items-center gap-2" key={item._id}>
                <ul className=''>
                    <div className='flex items-center gap-2 cursor-pointer' onClick={() => setSubCategoryOpen(!subCategoryOpen)}>
                        <input
                            type="checkbox"
                            className="cursor-pointer"
                            checked={item.isChecked}
                            onChange={() => handleCategoryCheck(item._id)}
                        />
                        {item?.name}
                    </div>
                    {
                        subCategoryOpen &&
                        item?.subCategory?.map((subItem, i) => {
                            return (
                                <li className="my-1 ml-5 flex items-center gap-2" key={i}>
                                    <input
                                        type="checkbox"
                                        className="cursor-pointer"
                                        checked={subCategoryList?.includes(subItem)}
                                        onChange={() => handleSubCategoryCheck(item._id, item?.name, subItem)}
                                    />
                                    {subItem}
                                </li>
                            )
                        })
                    }
                </ul>
            </li>
        </div>
    )
}

export default Category
