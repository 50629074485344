import React, { useState } from "react";
import img from "../Assets/IMG-20230810-WA0007.png";
import profile from "../Assets/Account.png";
import search from "../Assets/Vector.png";
import log from "../Assets/login.png";
import { Link, NavLink } from "react-router-dom";
import { useMain } from "../hooks/useMain";
import OutsideClickHandler from "react-outside-click-handler";

function Navbar() {
  var login = localStorage.getItem("glitch-token");
  const { searchProduct } = useMain();

  const [value, setValue] = useState("");
  const [searchBar, setSearchBar] = useState(false);
  const [searchI, setSearchI] = useState("");
  const [searchData, setSearchData] = useState([]);

  return (
    <>
      {/* <nav className="navbar navbar-expand-lg navbar-light bg-dark">
 <img className='logo' src={img}/>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item active">
      <Link className="nav-link" to='/'>Home</Link>
      
      </li>
      <li className="nav-item">
      <Link className="nav-link" to='/products'>Products</Link>
 
      </li>
      <li className="nav-item">
      <Link className="nav-link" to='/cart'>Cart</Link>
      </li>
    </ul>
    <form className="form-inline my-2 my-lg-0">
     <div className='nav-in'>
     <input/>
     <img src={search}/>


     </div>
     
      <Link className="nav-link" to='/profile'> <img className='nav-profile' src={profile}/></Link>
      <Link className="nav-link" to='/wishlist'> <i className="bi bi-heart-fill"></i></Link>

      <Link to='/login'><button className='login'>Login <img src={log}/></button></Link>
    </form>
  </div>
</nav> */}
      <nav className="newnavbar-na">
        <NavLink to="/">
          <img className="logog" src={img} alt="logo" />
        </NavLink>
        <input type="checkbox" id="checkbox" />

        <label htmlFor="checkbox" className="burger">
          <i className="bi bi-list" id="menu-icon"></i>
          <i className="bi bi-x" id="close-icon"></i>
        </label>

        <nav className="newnavbar">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/products">
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/cart">
                Cart
              </Link>
            </li>
          </ul>

          <OutsideClickHandler
            onOutsideClick={() => {
              setSearchBar(false);
            }}
          >
            <div className="nav-in">
              <input
                type="text"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
              <svg
                onClick={async () => {
                  let ans = await searchProduct(value);
                  console.log(ans);
                  setSearchData(ans.data);
                  if (ans.data.length > 0) {
                    setSearchBar(true);
                  } else {
                    setSearchBar(false);
                  }
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi cursor-pointer bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>

              {searchBar && (
                <div className="search-list">
                  {searchData?.map((element, index) => {
                    return (
                      <>
                        <Link className="m-0" to={`/product/${element._id}`}>
                          <div
                            key={index}
                            className="flex py-1"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <img
                              src={element.displayImage[0].url}
                              style={{ height: "30px", width: "30px" }}
                              alt=""
                            />
                            <h6 className="pl-2">{element.displayName}</h6>
                          </div>
                        </Link>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </OutsideClickHandler>

          {login && (
            <>
              <Link className="nav-link" to="/profile">
                <img className="nav-profile" src={profile} alt="nav" />
              </Link>
              <Link className="nav-link" to="/wishlist">
                <i className="bi bi-heart-fill"></i>
              </Link>
            </>
          )}

          {!login && (
            <Link to="/login" className="hover:no-underline">
              <button className="login buttonDesginer">
                Login <img src={log} alt="logo" />
              </button>
            </Link>
          )}
        </nav>
      </nav>
    </>
  );
}

export default Navbar;
