import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Product from '../Assets/Rectangle 38.png'
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../context/MainState';
import axios from 'axios';

function WishList() {
  const checkLogin = localStorage.getItem("glitch-token");

  const [loading, setLoading] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [cart, setCart] = useState([]);

  const { getWishList, modifyUserCart } = useMain();

  const getData = async () => {
    const res = await getWishList();
    console.log(res?.data?.products);
    setCart(res?.data?.products);
  };

  useEffect(() => {
    if (checkLogin) {
      getData();
    }

    return () => { };
  }, [refreshFlag]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [newData, setNewData] = useState(null);
  const navigate = useNavigate();

  async function MoveCart(cartId, variant) {
    if(checkLogin)
    {
      try {
        const res = await axios({
          method: "post",
          url: `${baseUrl}/api/wishlist/moveproduct/${cartId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("glitch-token")}`,
          },
        });
  
        if (res.data.status === "success") {
          alert(res.data.data.message);
          setNewData(res.data.data.wishlist.products);
          navigate('/cart');
        } else {
          alert(res.data.data.error);
        }
      } catch (e) { }
  
      try {
        const res = await modifyUserCart({ productId: cartId, type: "add", variant: variant });
      } catch (e) { }
    }
    else
    {
      alert('Please login');
    }
  }

  async function RemoveWishList(productId, product) {
    if (checkLogin) {
      try {
        // let url;
        // if (flag === "false") {
        //   url = ;
        // } else {
        //   url = `${baseUrl}/api/wishlist/product/${productId}/subtract`;
        // }

        const res = await axios({
          method: "post",
          url: `${baseUrl}/api/wishlist/product/${productId}/subtract`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('glitch-token')}`,
          },
        });

        if (res.data.status === "success") {
          setRefreshFlag(!refreshFlag);
          alert(res.data.data.message);
        } else {
          alert(res.data.data.error);
        }
      } catch (e) { }

    } else {
      // Login
      alert("Please login");
    }
  }

  return (
    <>

      <div className='wishlist'>
        <i className="bi bi-heart"></i>
        <h1>My Wishlist</h1>
        <br />
        <>
          {cart.map((e, index) => {
            return (
              <div key={index} className='wishlist-products'>
                <div className='wishlist-products-img'>
                  <img src={e?.product?.displayImage[0]?.url} />
                </div>

                <div className='wishlist-products-details'>
                  <h1>{e?.product?.displayName}</h1>
                  <div className='star'>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <p>(200 Rating)</p>
                  </div>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry . Lorem Ipsum has been the industry's standard dummy</p> */}
                </div>

                <div className='wishlist-products-button'>
                  <button onClick={()=>{
                    MoveCart(e?.product?._id, e?.product?.priceVarient[0]?.varient);
                  }} className='buy buttonDesginer'><i className="bi bi-cart-plus"></i>Buy</button>
                  <button onClick={()=>{
                    RemoveWishList(e.product._id);
                  }} className='trash'><i className="bi bi-trash"></i>Remove</button>
                </div>
              </div>
            );
          })}
        </>

      </div>
      <br />

      <Footer />
    </>
  );
};

export default WishList;
