import React from 'react'
// import Navbar from '../Components/Navbar';
import Banner from '../Components/Banner';
import Arrivel from '../Components/Arrivel';
import ArrivelProduct from '../Components/ArrivelProduct';
import Category from '../Components/Category';
import BestSeller from '../Components/BestSeller';
import BestSellerCard from '../Components/BestSellerCard';
import Testimonials from '../Components/Testimonials';
import Footer from '../Components/Footer';

function Home() {
  
  return (
    <>
      <div className="outer-container">
        <div className="background-image"></div>
        <div className="inner-container">
          <Banner />
          <Arrivel />
          <ArrivelProduct />
          <Category />
          <BestSeller />
          <BestSellerCard />
          <Testimonials />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Home