import axios from "axios";

import {
  GET_ERROR,
  GET_LOADING,
  GET_SUCCESS,
  POST_ERROR,
  POST_LOADING,
  POST_SUCCESS,
  POST_RESET,
  POST_GENARATE_LOADING,
  POST_GENARATE_SUCCESS,
  POST_GENARATE_ERROR,
} from "./imageActionType";
// import fetchData from "../../utils/request";

// Define your action types

// Example action creators for different HTTP methods
// export const fetchGet = (url) => async (dispatch) => {
//   try {
//     const data = await fetchData(url, "GET");
//     // Dispatch a success action with the fetched data
//     dispatch({ type: "FETCH_SUCCESS", payload: data });
//   } catch (error) {
//     // Handle errors here and dispatch an error action if needed
//     dispatch({ type: "FETCH_ERROR", error: error.message });
//   }
// };

export const uploadImageSticker = (url, requestData) => async (dispatch) => {
  try {
    console.log(url, requestData, "shadab");
    dispatch({ type: POST_LOADING });
    const token = localStorage.getItem('glitch-token');
    console.log(token)
    const headers = {
       "Authorization": `Bearer ${token}`
    };
    const response = await axios.post(url, requestData ,{headers: headers});
    dispatch({ type: POST_SUCCESS, payload: response.data });
    console.log(response.data);
  } catch (error) {
    console.error("Error in uploadImageSticker:", error);
    dispatch({ type: POST_ERROR, error: error.message });
  }
};
export const ResetUploadImageSticker = () => async (dispatch) => {
  try {
    dispatch({ type: POST_RESET, payload: {} });
  } catch (error) {
    console.error("Error in uploadImageSticker:", error);
    dispatch({ type: POST_ERROR, error: error.message });
  }
};
export const GetImageSticker = (url, paramsID) => async (dispatch) => {
  try {
    dispatch({ type: GET_LOADING });
    const response = await axios.get(url, {
      params: paramsID,
    });

    dispatch({ type: GET_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error in GetImageSticker:", error);
    dispatch({ type: GET_ERROR, error: error.message });
  }
};
export const genarateImageSticker = (url, requestData) => async (dispatch) => {
  try {
    dispatch({ type: POST_GENARATE_LOADING });
    // const data = new URLSearchParams();
    // data.append("reqId", requestData);
    const token = localStorage.getItem('glitch-token');
    console.log(token)
    const headers = {
       "Authorization": `Bearer ${token}`
    };
    const response = await axios.post(`${url}?reqId=${requestData}`, {headers : headers});

    dispatch({ type: POST_GENARATE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: POST_GENARATE_ERROR, error: error.message });
  }
};
// export const GetImageSticker = (url, requestData) => async (dispatch) => {
//   try {
//     console.log(url, requestData,"shadab")
//     dispatch({ type: GET_LOADING });

//     const response = await axios.get(url, requestData);
//     dispatch({ type: GET_SUCCESS, payload: response.data });
//     console.log(response.data )
//   } catch (error) {
//     console.error("Error in uploadImageSticker:", error);
//     dispatch({ type: GET_ERROR, error: error.message });
//   }
// };
// export const uploadImageSticker = (url, requestData) => async (dispatch) => {
//   try {
//     dispatch({ type: POST_LOADING });

//     const response = await axios.post(url, requestData);

//     dispatch({ type: POST_SUCCESS, payload: response.data });
//   } catch (error) {
//     console.error("Error in uploadImageSticker:", error);
//     dispatch({ type: POST_ERROR, error: error.message });
//   } finally {
//     // Set loading to false after the API request is complete
//     dispatch({ type: POST_LOADING });
//   }
// };

// Similar action creators for PATCH, PUT, and DELETE
