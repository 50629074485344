import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import img from "../Assets/Ellipse 120.png";
import img2 from '../Assets/image-removebg-preview (97) 1.png'
import img3 from '../Assets/pngegg (5) 1.png'
import img4 from '../Assets/pngegg (5) 2.png'
import { useMain } from "../hooks/useMain";

function ProfilePage() {
  let user = JSON.parse(localStorage.getItem('glitch-user'));

  const {updateUserAddress}=useMain();

  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    wholeAddress: "",
    pinCode: "",
  });
  const [loadFlag, setLoadFlag] = useState(true);
  const [shippingAddress, setShippingAddress] = useState([]);

  useEffect(()=>{
    getData();
  },[]);

  const getData=()=>{
    setLoadFlag(true);
    setValue({...value, name: user?.displayName});
    setValue({...value, phone: user?.phoneNumber});
    setValue({...value, email: user?.email});
    
    if(user.shippingAddress[0])
    {
      let t=user.shippingAddress[0].address.split(', ');
      setValue({
        ...value,
        name: t[0],
        email: t[1],
        phone: t[2],
        city: t[3],
        state: t[4],
        wholeAddress: t[5],
        pinCode: user.shippingAddress[0].pincode,
      });
    }
    setLoadFlag(false);
  };

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(value);
    setLoadFlag(true);

    const data = {
      shippingAddress: [
        ...shippingAddress,
        {
          address:
            value.name +
            ", " +
            value.email +
            ", " +
            value.phone +
            ", " +
            value.state +
            ", " +
            value.city +
            ", " +
            value.wholeAddress,
          pincode: value.pinCode,
          isDefault: shippingAddress?.length === 0 ? true : false,
        },
      ],
    };

    try {
      const result = await updateUserAddress(data);
      // console.log(result)
      if (result.status === "success") {
        localStorage.setItem(
          "glitch-user",
          JSON.stringify({
            ...user,
            shippingAddress: result?.data?.user?.shippingAddress,
          })
        );
       
        alert(result.message);
      } else {
        alert(result.error.message);
      }
    } catch (err) {
      alert("Internal server error.");
    }

    setLoadFlag(false);
  };

  return (
    <>
      {loadFlag ? 'Loading ..' : <>
        <div className="userprofile">
          <div className="profle-left">
            <img className="profile-img" src={img} />
            <h1>{value.name}</h1>
            <button onClick={()=>{
              localStorage.removeItem('glitch-token');
              localStorage.removeItem('glitch-user');
              window.location.href='/login';
            }} className="logout-btn buttonDesginer">Logout</button>
          </div>

          <form className="profle-right" onSubmit={handleSubmit}>
            <div className="details">
              <h1>Name : </h1>
              <input className="profileinput" value={value.name} onChange={handleChange} name='name' />
            </div>

            <div className="details">
              <h1>Mobile No. : </h1>
              <input className="profileinput" value={value.phone} onChange={handleChange} name='phone' />
            </div>
            <div className="details">
              <h1>Email ID : </h1>
              <input className="profileinput" value={value.email} onChange={handleChange} name='email' />
            </div>

            <div className="details">
              <h1>Address : </h1>
              <input className="profileinput" value={value.wholeAddress} onChange={handleChange} name='wholeAddress' />
            </div>
            <div className="details">
              <h1>State : </h1>
              <input className="profileinput" value={value.state} onChange={handleChange} name='state' />
            </div>
            <div className="details">
              <h1>City : </h1>
              <input className="profileinput" value={value.city} onChange={handleChange} name='city' />
            </div>
            <div className="details">
              <h1>Pin Code : </h1>
              <input className="profileinput" value={value.pinCode} onChange={handleChange} name='pinCode' />
            </div>
            <button className="submit-btn buttonDesginer">Submit</button>
          </form>
        </div>
        <br />
        <br />
        <img className="pr-thunder" src={img3} />
        <br />
        <img className="pr-thunder2" src={img4} />
        <br />
        <img className="pr-anime" src={img2} />
      </>}

      <Footer />
    </>
  );
}

export default ProfilePage;
