const initialState = {
  cordinate: { x: null, y: null },
};

const cordinateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CORDINATE":
      return { ...state, cordinate: action.payload };
    default:
      return state;
  }
};

export default cordinateReducer;
