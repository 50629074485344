import React, { useState } from "react";
import img from "../Assets/IMG-20230810-WA0007.png";
import { useMain } from "../hooks/useMain";
import { useNavigate } from "react-router-dom";

function Footer({}) {
  const { newsPost } = useMain();
  const [text1, setText1] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let ans = await newsPost({ email: text1 });
    console.log(ans);
    // notify('success', 'Newsletter created');
    alert("Newsletter created");
    setText1("");
  };

  return (
    <>
      <div className="footer">
        <div className="topfoot">
          <div className="fleft">
            <img className="flogo" src={img} />
            <ul>
              <li
                onClick={() => {
                  navigate("/aboutus");
                }}
              >
                About
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                Careers
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                Press
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                Customer Care
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                Service
              </li>
            </ul>
          </div>

          <form onSubmit={handleSubmit} className="fright ">
            <p>Get the freshest news from us</p>
            <div className="form-wrap">
              <input
                placeholder="Your email address…"
                value={text1}
                onChange={(e) => {
                  setText1(e.target.value);
                }}
              />
              <button type="submit" className="buttonDesginer">
                Subscribe
              </button>
            </div>
          </form>
        </div>

        <div className="btfoot">
          <ul>
            <li>Terms & Conditions </li>
            <li> | </li>
            <li> Privacy Policy </li>
            <li> | </li>
            <li> Accessibility </li>
            <li> | </li>
            <li> Legal </li>
            <li> |</li>
          </ul>

          <p>Strix Digital © Varlyqcompany 20203. All right reserved</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
