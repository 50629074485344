import React from 'react'
import MainContext from './MainContext';

// export var baseUrl = 'https://glitch-xm4q.onrender.com';
export var baseUrl = 'https://glitch-backend-node-9la3v.ondigitalocean.app';
// export var baseUrl = 'http://localhost:8080';

const MainState = (props) => {
    const login = async ({ email, password }) => {
        let resp = await fetch(`${baseUrl}/user/signin`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });
        let data = await resp.json();
        return data;
    };

    const login1 = async ({ email, displayName }) => {
        let resp = await fetch(`${baseUrl}/user/signin1`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ email, displayName })
        });
        let data = await resp.json();
        return data;
    };

    const signup = async ({ displayName, email, password, phoneNumber }) => {
        let resp = await fetch(`${baseUrl}/user/signup`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ displayName, email, password, phoneNumber })
        });
        let data = await resp.json();
        return data;
    };

    const getProducts = async () => {
        let resp = await fetch(`${baseUrl}/product/all`, {
            method: 'GET',
        });
        let data = await resp.json();
        return data;
    };

    const getProducts1 = async () => {
        let resp = await fetch(`${baseUrl}/product/random/4`, {
            method: 'GET',
        });
        let data = await resp.json();
        return data;
    };

    const getProduct = async (id) => {
        let resp = await fetch(`${baseUrl}/product/${id}`, {
            method: 'GET',
        });
        let data = await resp.json();
        return data;
    };

    const addToCart = async (product, type, variant) => {
        let resp = await fetch(`${baseUrl}/user/cart/product/${product}/${type}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('glitch-token')}`,
            },
            body: JSON.stringify({
                productId: product,
                type,
                variant
            })
        });
        let data = await resp.json();
        return data;
    };

    const getAllCarts = async () => {
        const response = await fetch(`${baseUrl}/user/cart`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('glitch-token')}`,
            },
            redirect: "follow",
        });
        // if (response?.status === 401) {
        //     logoutUtil();
        // }
        const resData = await response?.json();
        return resData;
    };

    const getWishList = async () => {
        const response = await fetch(`${baseUrl}/api/wishlist/getwishlist`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('glitch-token')}`,
            },
            redirect: "follow",
        });
        // if (response?.status === 401) {
        //     logoutUtil();
        // }
        const resData = await response?.json();
        return resData;
    };

    const newsPost = async (data) => {
        const response = await fetch(`${baseUrl}/user/news`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            redirect: "follow",
        });
        const resData = await response?.json();
        return resData;
    };

    const modifyUserCart = async (data) => {
        const response = await fetch(`${baseUrl}/user/cart/product/${data?.productId}/${data?.type}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('glitch-token')}`
            },
            body: JSON.stringify(data),
            redirect: "follow",
        });
        const resData = await response?.json();
        return resData;
    };

    const updateUserAddress = async (data) => {
        try {
            const response = await fetch(`${baseUrl}/user/address/update`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('glitch-token')}`
                },
                body: JSON.stringify(data),
            })
            const resData = await response?.json();
            return resData;
        } catch (err) {
            console.log(err);
        }
    };

    const searchProduct = async (data) => {
        try {
            const response = await fetch(`${baseUrl}/products/searchProduct?query=${data}`, {
                method: "GET",
            })
            const resData = await response?.json();
            return resData;
        } catch (err) {
            console.log(err);
        }
    };

    const getFilteredProducts = async (data) => {
        try {
            const response = await fetch(`${baseUrl}/product/filter`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('glitch-token')}`
                },
                body: JSON.stringify(data),
            })
            const resData = await response?.json();
            return resData;
        } catch (err) {
            console.log(err);
        }
    };

    const getTopCategories = async () => {
        const response = await fetch(`${baseUrl}/product/category/all`, {
            method: "GET",
            redirect: "follow",
        });

        const resData = await response?.json();
        return resData;
    };

    return (
        <>
            <MainContext.Provider value={{ login, signup, getProducts, getProduct, addToCart, getAllCarts, newsPost, getWishList, modifyUserCart, updateUserAddress, getFilteredProducts, login1, searchProduct, getTopCategories, getProducts1 }}>
                {props.children}
            </MainContext.Provider>
        </>
    );
};

export default MainState;
